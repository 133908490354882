@import '../../styles/vars';
@import '../../styles/mixins/type';
@import '../../styles/mixins/breakpoints';

.dashboard-header-nav {
  ul,
  li {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  li {
    display: inline-block;
  }
  .navItemReversed-en {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    span {
      margin-right: 5px;
    }
  }

  .navItemReversed-ar {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    span {
      margin-left: 5px;
    }
  }

  &__link {
    font-size: 16px;
    color: $color-black50;
    display: inline-block;
    padding: 4px 16px;
    border-radius: 2em;

    &:hover {
      color: $color-button-primary-hover;
    }
    &:active,
    &:focus {
      color: $color-violet-hover;
      background: $color-black95;
    }
    &.active {
      &,
      &:active,
      &:focus {
        color: $color-violet-hover;
        background: #9ec8bf;
      }
    }
  }

  @include media-breakpoint-down(lg) {
    & {
      margin: 0 -1.5rem;
      padding: 0 0 8px;
      overflow: auto;
      white-space: nowrap;

      ul {
        padding: 0 1.5rem;
      }
    }
  }
}
