@import '../../styles/vars';
@import '../../styles/mixins/type';
@import '../../styles/mixins/breakpoints';

.sidebar {
  &__close {
    display: none;
    height: 110px;
  }
  &__close-button {
    color: #fff;
    font-size: 30px;
    opacity: 0.5;
  }

  &__logo {
    padding: 0 40px 0;
    display: flex;

    svg {
      width: 125px;
      height: 125px;
      vertical-align: middle;
    }
  }
  &__nav {
    margin: 32px 0;
  }
  &__footer {
    font-size: 12px;
    color: $color-black40;
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 2rem 0.5rem;

    a {
      color: $color-black40;
      transition: color 150ms linear;

      &:hover,
      &:active {
        color: $color-black80;
      }
    }
  }
  @include media-breakpoint-down(lg) {
    &__close {
      display: block;
    }
    &__logo {
      display: none;
    }
  }
}
